import React from "react";

export interface NewsItemProps {
  title: string;
  subline: string;
  children: React.ReactNode;
}

const NewsItem: React.FC<NewsItemProps> = ({ title, subline, children }) => {
  return (
    <div>
      <h2 className="mb-2">{title}</h2>
      <p className="text-sm text-slate-500">{subline}</p>
      {children}
    </div>
  );
};

export default NewsItem;
