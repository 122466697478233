/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../templates/content-page";
import NewsItem from '../components/news-item';
import {Link} from 'gatsby';
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p",
    br: "br"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Aktuelles"), "\n", React.createElement(NewsItem, {
    title: "Neue Balintgruppe",
    subline: "Eingetragen von Psychotherapiepraxis Düsseldorf"
  }, React.createElement(_components.p, null, "14-tägig ab 04.09.2024 jeweils Mittwochs (Dr. Schröter, Dr. Schöttes)"), React.createElement("ul", null, React.createElement("li", null, "Für Ärzt:Innen und Psycholog:Innen"), "\n", React.createElement("li", null, "Anerkannt von Ärztekammer und KV für die Facharztweiterbildung und Psychosomatische Grundversorgung"), "\n", React.createElement("li", null, "Teilnahme auch mit abgeschlossener Weiterbildung")), React.createElement("p", {
    className: "info-box"
  }, "Weitere Informationen telefonisch im ", React.createElement(Link, {
    to: "/kontakt"
  }, "Sekretariat"), " oder unter: ", React.createElement("a", {
    href: "mailto:balint@psychotherapiepraxis-duesseldorf.de"
  }, "balint@psychotherapiepraxis-duesseldorf.de"))), "\n", React.createElement("hr"), "\n", React.createElement(NewsItem, {
    title: "Offene Sprechstunde von Dr. Šoljan",
    subline: "Eingetragen von Psychotherapiepraxis Düsseldorf"
  }, React.createElement(_components.p, null, "Offene Sprechstunde von Dr. Šoljan:", React.createElement(_components.br), "\n", "Freitags 10 - 12 Uhr.\n", React.createElement("p", {
    className: "info-box"
  }, "Für Termine in der offenen Sprechstunde rufen Sie bitte im ", React.createElement(Link, {
    to: "/kontakt"
  }, "Sekretariat"), " an."))), "\n", React.createElement("hr"), "\n", React.createElement(NewsItem, {
    title: "Freie Therapieplätze",
    subline: "Eingetragen von Psychotherapiepraxis Düsseldorf"
  }, React.createElement("p", {
    className: "info-box"
  }, "Freie Therapieplätze können Sie in unserem ", React.createElement(Link, {
    to: "/kontakt"
  }, "Sekretariat"), " erfragen.")));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
